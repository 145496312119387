// Generic UI Colours
$white-colour: #ffffff !default;
$black-colour: #000000 !default;

$white-colour--25-percent: rgba(255, 255, 255, 0.25) !default;
$white-colour--50-percent: rgba(255, 255, 255, 0.5) !default;
$white-colour--75-percent: rgba(255, 255, 255, 0.75) !default;

$black-colour--25-percent: rgba(0, 0, 0, 0.25) !default;
$black-colour--50-percent: rgba(0, 0, 0, 0.5) !default;
$black-colour--75-percent: rgba(0, 0, 0, 0.75) !default;

$black--hover-state: rgba(117, 117, 117, 0.08) !default;

$background-colour: #fafafa !default;
$font-colour: #202020 !default;
$border-color: rgba(0, 0, 0, 0.12) !default;

$grey-colour--lightest: #eeeeee !default;
$grey-colour--lighter: #e0e0e0 !default;
$grey-colour--light: #bdbdbd !default;
$grey-colour: #aaaaaa !default;
$grey-colour--dark: #666666 !default;

$jplus-dark-colour: #212124 !default;

// BRAND COLOURS | Official J+ Brand
$primary-colour: #da0034 !default;
$secondary-colour: #bdbdbd !default;
$tertiary-colour: #000000 !default;

$draft-colour: $grey-colour--lighter !default;
$pending-colour: #ed6c02 !default;
$approved-colour: #4caf50 !default;

$info-colour--dark: #0022aa !default;
$info-colour: #0066ff !default;
$info-colour--light: #cce0ff !default;

$warning-colour--dark: #ffaa00 !default;
$warning-colour: #ffcc00 !default;
$warning-colour--light: #fff8dd !default;

$success-colour--dark: #125d0c !default;
$success-colour: #00a72f !default;
$success-colour--light: #cceecc !default;

$error-colour--dark: #a50028 !default;
$error-colour: #cc0000 !default;
$error-colour--light: #ffdddd !default;

$navigation-colour: #da0034 !default;
$navigation-colour--light: #fbe0e7 !default;

// Material design palette
$md-primary-colour-palette: (
  50: #fbe0e7,
  100: #f4b3c2,
  200: #ed809a,
  300: #e54d71,
  400: #e02652,
  500: #da0034,
  600: #d6002f,
  700: #d00027,
  800: #cb0021,
  900: #c20015,
  A100: #ffebec,
  A200: #ffb8bb,
  A400: #ff858b,
  A700: #ff6b73,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000
  )
);

// Material design palette
$jplus-accent-colour-palette: (
  50: #fff3e0,
  100: #ffe0b2,
  200: #ffcc80,
  300: #ffb74d,
  400: #ffa726,
  500: #ed6c02,
  600: #fb8c00,
  700: #f57c00,
  800: #ef6c00,
  900: #e65100,
  A100: #ffd180,
  A200: #ffab40,
  A400: #ff9100,
  A700: #ff6d00,
  contrast: (
    50: rgba(black, 0.87),
    100: rgba(black, 0.87),
    200: rgba(black, 0.87),
    300: rgba(black, 0.87),
    400: rgba(black, 0.87),
    500: rgba(black, 0.87),
    600: rgba(black, 0.87),
    700: rgba(black, 0.87),
    800: white,
    900: white,
    A100: rgba(black, 0.87),
    A200: rgba(black, 0.87),
    A400: rgba(black, 0.87),
    A700: black
  )
);
