@use 'theme/m3-theme';
@use 'colours';

._page {
  padding: 24px 48px;
}

.verified {
  color: m3-theme.$success-state;
}

.table__row {
  cursor: pointer;

  &:hover {
    background-color: m3-theme.$vivid-primary-gray;
  }
}

.jplus-alert-error {
  &.mat-mdc-snack-bar-container {
    .mat-mdc-snackbar-surface {
      background-color: colours.$primary-colour;
    }

    .mat-mdc-button.mat-mdc-snack-bar-action:not(:disabled) {
      color: white;
    }
  }
}

.company-switcher__menu {
  width: 100%;
  max-height: 400px;
  min-width: 300px !important;
  max-width: 400px !important;
}

.jplus-table-title {
  margin: 0 0 12px;
  font-weight: 500;
}

.mat-table-icon {
  color: m3-theme.$vivid-primary !important;
}
