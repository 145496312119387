@use 'app/scss/custom-angular-theme';
@use 'app/scss/typography';
@use 'app/scss/colours';

html,
body {
  height: 100%;
}

body {
  margin: 0;
  background: colours.$background-colour;
}

.j-loading-shade {
  position: absolute;
  top: 56px;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(255, 255, 255, 1);
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;

  &.is-page {
    top: 240px;
    right: 48px;
    left: 48px;
  }
}

.not-allowed {
  cursor: not-allowed;
}

.jplus-backdrop {
  --tw-backdrop-blur: blur(4px);
  --tw-backdrop-brightness: brightness(0.9);
  --tw-backdrop-contrast: contrast(1.2);
  --tw-backdrop-grayscale: grayscale(0);
  --tw-backdrop-hue-rotate: hue-rotate(0deg);
  --tw-backdrop-invert: invert(0);
  --tw-backdrop-opacity: opacity(0.7);
  --tw-backdrop-saturate: saturate(1.1);
  --tw-backdrop-sepia: sepia(0);

  --tw-backdrop-filters:
    var(--tw-backdrop-blur)
    var(--tw-backdrop-brightness)
    var(--tw-backdrop-contrast)
    var(--tw-backdrop-grayscale)
    var(--tw-backdrop-hue-rotate)
    var(--tw-backdrop-invert)
    var(--tw-backdrop-opacity)
    var(--tw-backdrop-saturate)
    var(--tw-backdrop-sepia);

  -webkit-backdrop-filter: var(--tw-backdrop-filters);
  backdrop-filter: var(--tw-backdrop-filters);
}

.mat-mdc-dialog-title {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.mat-mdc-dialog-actions.mdc-dialog__actions  {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  padding: 8px 24px;
}

.mdc-dialog__content {
  padding: 24px !important;
}
