@import url('https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined');

$font-family: 'DM Sans' !default;
$font-spacing: 0.5px !default;

$font-size-base: 1rem !default; // Assumes the browser default, typically `16px`
$font-size-lg: $font-size-base * 1.125 !default;
$font-size-sm: $font-size-base * 0.875 !default;

$font-weight-normal: 400 !default;
$font-weight-headline: 500 !default;
$font-weight-bold: 700 !default;

$font-weight-base: $font-weight-normal !default;

$line-height-base: 1rem !default;

$font-margin-base: 12px !default;

$h1-font-size: $font-size-base * 2.5 !default;
$h1-line-height: $line-height-base * 3 !default;
$h1-margin-bottom: 24px !default;
$h2-font-size: $font-size-base * 1.75 !default;
$h2-line-height: $line-height-base * 2.25 !default;
$h2-margin-bottom: 24px !default;
$h3-font-size: $font-size-base * 1.5 !default;
$h3-line-height: $line-height-base * 2 !default;
$h3-margin-bottom: 24px !default;
$h4-font-size: $font-size-base * 1.25 !default;
$h4-line-height: $line-height-base * 1.875 !default;
$h4-margin-bottom: 24px !default;
$h5-font-size: $font-size-base * 1.125 !default;
$h5-line-height: $line-height-base * 1.625 !default;
$h5-margin-bottom: 24px !default;
$h6-font-size: $font-size-base !default;
$h6-line-height: $line-height-base * 1.5 !default;
$h6-margin-bottom: 24px !default;

$p-font-size: $font-size-base !default;
$p-line-height: $line-height-base * 1.5 !default;
$p-margin-bottom: 24px !default;

$small-font-size: 80% !default;

$input-font-size: 1rem !default;

$hr-border-width: 1px !default;

body {
  font-family: $font-family;
  letter-spacing: $font-spacing;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: inherit;
  margin-top: 0;
}

h1 {
  margin-top: 0;
  font-size: $h1-font-size;
}
h2 {
  margin-top: 0;
  font-size: $h2-font-size;
}
h3 {
  margin-top: 0;
  font-size: $h3-font-size;
}
h4 {
  margin-top: 0;
  font-size: $h4-font-size;
}
h5 {
  margin-top: 0;
  font-size: $h5-font-size;
}

h6 {
  margin-top: 0;
  font-size: $h6-font-size;
}

p {
  margin-top: 0 !important;
  font-size: $p-font-size;
}

p,
span {
  &.light {
    opacity: 0.5;
  }
}
