@use 'sass:map';
@use '@angular/material' as mat;

$black-87-opacity: rgba(black, 0.87) !default;
$black-12-opacity: rgba(0, 0, 0, 0.12) !default;
$dark-vivid-secondary-text: rgba(black, 0.6) !default;
$dark-primary-text: rgba(black, 0.87) !default;
$vivid-menu-tab: rgba(62, 209, 130, 0.2) !default;
$light-primary-text: white !default;

$vivid-primary: #da0034 !default;
$vivid-primary-dark: #9f2237 !default;
$vivid-primary-light: #f8d8de !default;
$vivid-primary-light-secondary: #da5d65 !default;
$vivid-primary-gray: #fafafa !default;
$success-state: #007a39 !default;

$_palettes: (
  primary: (
    0: #000000,
    10: #000000,
    20: $vivid-primary-light,
    25: $vivid-primary-light,
    30: $vivid-primary-light-secondary,
    35: $vivid-primary-light-secondary,
    40: $vivid-primary,
    50: $vivid-primary,
    60: $vivid-primary,
    70: $vivid-primary,
    80: $vivid-primary-dark,
    90: $vivid-primary-light,
    95: $vivid-primary-dark,
    98: $vivid-primary-dark,
    99: $vivid-primary-dark,
    100: $light-primary-text
  ),
  secondary: (
    0: #000000,
    10: #333333,
    20: #666666,
    25: #666666,
    30: #999999,
    35: #999999,
    40: #666666,
    50: #808080,
    60: #666666,
    70: #4d4d4d,
    80: #333333,
    90: #cccccc,
    95: #cccccc,
    98: #cccccc,
    99: #cccccc,
    100: #cccccc
  ),
  tertiary: (
    0: #000000,
    10: #333333,
    20: #666666,
    25: #666666,
    30: #999999,
    35: #999999,
    40: #666666,
    50: #808080,
    60: #666666,
    70: #4d4d4d,
    80: #333333,
    90: #1a1a1a,
    95: #1a1a1a,
    98: #1a1a1a,
    99: #1a1a1a,
    100: #1a1a1a
  ),
  neutral: (
    0: #000000,
    10: #333333,
    20: #2e3132,
    25: #393c3d,
    30: #444748,
    35: #505354,
    40: #5c5f5f,
    50: #747878,
    60: #8e9192,
    70: #a9acac,
    80: #c4c7c7,
    90: #e1e3e3,
    95: $light-primary-text,
    98: $light-primary-text,
    99: $light-primary-text,
    100: $light-primary-text,
    4: #0b0f0f,
    6: #101415,
    12: #1d2021,
    17: #333333,
    22: #808080,
    24: #999999,
    87: $light-primary-text,
    92: $light-primary-text,
    94: $light-primary-text,
    96: #ffffff
  ),
  neutral-variant: (
    0: $light-primary-text,
    10: #ededed,
    20: #999999,
    25: #808080,
    30: #666666,
    35: #4d4d4d,
    40: $black-12-opacity,
    50: $black-12-opacity,
    60: #666666,
    70: #999999,
    80: $black-12-opacity,
    90: whitesmoke,
    95: $light-primary-text,
    98: $light-primary-text,
    99: $light-primary-text,
    100: $light-primary-text
  ),
  error: (
    0: #000000,
    10: #410002,
    20: #690005,
    25: #7e0007,
    30: #93000a,
    35: #a80710,
    40: #ba1a1a,
    50: #de3730,
    60: #ff5449,
    70: #ff897d,
    80: $light-primary-text,
    90: $light-primary-text,
    95: $light-primary-text,
    98: $light-primary-text,
    99: $light-primary-text,
    100: $light-primary-text
  )
);

$_rest: (
  secondary: map.get($_palettes, secondary),
  neutral: map.get($_palettes, neutral),
  neutral-variant: map.get($_palettes, neutral-variant),
  error: map.get($_palettes, error)
);
$_primary: map.merge(map.get($_palettes, primary), $_rest);
$_tertiary: map.merge(map.get($_palettes, tertiary), $_rest);

$workflow-light-theme: mat.define-theme(
  (
    color: (
      theme-type: light,
      primary: $_primary,
      tertiary: $_tertiary
    ),
    typography: (
      brand-family: 'DM Sans, sans-serif',
      plain-family: 'DM Sans, sans-serif',
      bold-weight: 600
    )
  )
);
